function formatCurrency(value, formatOptions) {
  return new Intl
    .NumberFormat(formatOptions.locale, formatOptions.options)
    .format(value);
}

export default {
  methods: {
    currencyBRL(value) {
      const toUseValue = value || 0;
      const valueNumber = Number.parseInt(toUseValue, 10) / 100;
      return formatCurrency(valueNumber, {
        locale: 'pt-BR',
        options: { style: 'currency', currency: 'BRL' },
      });
    },
    phone(value) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    },
    cnpj(value) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    },
    cep(value) {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{5})(\d)/, '$1-$2');
    },
  },
};
