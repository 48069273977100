<template>
  <nav aria-label="Breadcrumbs" class="breadcrumbs">
    <slot name="default"></slot>
    <span class="current-page">{{ pageName }}</span>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    pageName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$slots.default.forEach((link) => {
      link.elm.classList.add('breadcrumbs-link');
    });
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  @apply hidden lg:flex items-center text-gray-500 text-sm gap-1;
}

.breadcrumbs-list {
  @apply flex items-center text-gray-500 text-sm;
}

.breadcrumbs-link {
 @apply text-primary-700 flex items-center gap-1;

 &:hover {
  @apply underline;
 }

 &:not(:last-child):after {
    content: url(../../public/chevron-right.svg);
    @apply w-5 h-5;
  }
}
</style>
