var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"input-container",class:[
      { disabled: _vm.disabled },
      { invalid: _vm.invalid },
      { 'textarea': _vm.type === 'textarea' }
    ]},[(_vm.$slots.default)?_c('div',{staticClass:"icon-container"},[_vm._t("default")],2):_vm._e(),_c('div',{staticClass:"input-text-group"},[(_vm.addonLeft)?_c('div',[_vm._v(" "+_vm._s(_vm.addonLeft)+" ")]):_vm._e(),(_vm.type === 'cash')?_c('span',[_vm._v(" R$ ")]):_vm._e(),(_vm.type === 'cash')?_c('money',_vm._b({staticClass:"input",attrs:{"id":"money-input","value":_vm.maskedValue,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":_vm.handleInput}},'money',_vm.money,false)):_vm._e(),(_vm.type === 'text')?_c('input',{staticClass:"input",attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder,"type":_vm.inputType},domProps:{"value":_vm.maskedValue},on:{"input":_vm.handleInput}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"input",class:{
          'textarea': _vm.type === 'textarea'
        },attrs:{"placeholder":_vm.placeholder,"maxLength":_vm.maxLength,"disabled":_vm.disabled,"type":_vm.inputType},domProps:{"value":_vm.maskedValue},on:{"input":_vm.handleInput}}):_vm._e(),(_vm.addonRight)?_c('div',[_vm._v(" "+_vm._s(_vm.addonRight)+" ")]):_vm._e()],1),(_vm.loading)?_c('div',{staticClass:"icon-container"},[_c('LoadingAnimation')],1):_vm._e(),(_vm.type === 'password-toggle')?_c('button',{staticClass:"icon-container",attrs:{"type":"button"},on:{"click":_vm.togglePassword}},[_c('EyeIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPassword),expression:"!showPassword"}]}),_c('EyeOffIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPassword),expression:"showPassword"}]})],1):_vm._e(),(_vm.clipboard)?_c('button',{staticClass:"icon-container",attrs:{"type":"button"},on:{"click":_vm.copyToClipboard}},[_c('ClipboardCopyIcon')],1):_vm._e()]),(_vm.invalid)?_c('p',{staticClass:"text-sm text-red-700"},[_vm._v(" "+_vm._s(_vm.required ? !_vm.hasValue ? 'Campo obrigatório' : _vm.errorMessage : _vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }