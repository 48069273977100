<!-- eslint-disable max-len -->
<template>
  <div class="alert" :class="`alert-${kind}`">
    <div class="flex items-start flex-grow gap-3">
      <span class="flex-shrink-0">
        <CheckCircleIcon v-if="kind === 'success'" size="24" />
        <ExclamationCircleIcon v-if="kind === 'warning'" size="24" />
        <InformationCircleIcon v-if="kind === 'info'" size="24" />
        <XCircleIcon v-if="kind === 'danger'" size="24" />
      </span>
      <slot name="default"></slot>
    </div>
    <button class="w-6 h-6 rounded-full flex flex-shrink-0 justify-center items-center hover:bg-black hover:bg-opacity-10" type="button" @click="handleClose">
      <XIcon size="20" />
    </button>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@vue-hero-icons/solid';

export default {
  name: 'Alert',
  props: {
    kind: {
      type: String,
      required: true,
    },
  },
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XIcon,
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  @apply flex gap-3 px-6 py-4;
}

.alert-success {
  @apply bg-green-100 text-green-700;
}

.alert-warning {
  @apply bg-yellow-100 text-yellow-700;
}

.alert-info {
  @apply bg-blue-100 text-blue-700;
}

.alert-danger {
  @apply bg-red-100 text-red-700;
}
</style>
