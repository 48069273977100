<template>
  <div class="badge-custom" :class="[kind, { chip }]">
    <span v-if="chip" class="chip-circle"></span>
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    chip: Boolean,
    kind: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-custom {
  @apply flex justify-center gap-1 px-2 rounded-full text-sm font-semibold;
  width: fit-content;
  height: fit-content;

  &.chip {
    @apply bg-white border border-gray-200 text-gray-900 font-normal;
  }

  &.default {
    &:not(.chip) {
      @apply bg-gray-100 text-gray-500;
    }

    & > .chip-circle {
      @apply bg-gray-400;
    }
}

  &.success {
    &:not(.chip) {
      @apply bg-green-100 text-green-700;
    }

    & > .chip-circle {
      @apply bg-green-500;
    }
  }

  &.warning {
    &:not(.chip) {
      @apply bg-yellow-100 text-yellow-700;
    }

    & > .chip-circle {
      @apply bg-yellow-400;
    }
  }

  &.danger {
    &:not(.chip) {
      @apply bg-red-100 text-red-700;
    }

    & > .chip-circle {
      @apply bg-red-600;
    }
  }

  &.info {
    &:not(.chip) {
      @apply bg-blue-100 text-blue-700;
    }

    & > .chip-circle {
      @apply bg-blue-500;
    }
  }

  &.primary {
    &:not(.chip) {
      @apply bg-primary-100 text-primary-700;
    }

    & > .chip-circle {
      @apply bg-primary-500;
    }
  }
}

.chip-circle {
  @apply w-2 h-2 border-0 rounded-full;
}
</style>
