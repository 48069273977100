<!-- eslint-disable max-len -->
<template>
  <div class="select-list dropdown-list" :class="{ 'full-width': fullWidth }">
    <button ref="button" type="button" class="button select-toggle" :class="{
    'theme-light': white, invalid, dropdown }">
      <div v-if="label" class="text-gray-500">{{ label }}</div>
      <div ref="option" class="select-option">
        <template v-if="placeholder">{{ placeholder }}</template>
      </div>
      <span>
        <ChevronDownIcon class="transform transition-transform duration-300" size="20" :class="arrowUp? 'rotate-180' : ''" />
      </span>
    </button>

    <div ref="dropdown" class="dropdown-menu w-72">
      <div v-if="search" class="select-option-search-container">
        <Input class="px-3 pt-1 pb-2" placeholder="Buscar" @input="handleInput">
        <SearchIcon size="20" />
        </Input>
      </div>
      <div class="dropdown-menu-list">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import {
  SearchIcon,
} from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import Input from './Input.vue';

export default {
  name: 'Select',
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    dropdown: Boolean,
    fullWidth: Boolean,
    label: String,
    placeholder: String,
    search: Boolean,
    value: String,
    white: Boolean,
  },
  components: {
    ChevronDownIcon,
    Input,
    SearchIcon,
  },
  data: () => ({
    arrowUp: false,
    invalid: false,
    tippyInstance: null,
  }),
  methods: {
    handleSelectOption({ content, value }) {
      this.switchSelectedContent(content);
      this.$emit('select', value);
      this.tippyInstance.hide();
    },
    handleInput(value) {
      this.$slots.default.forEach((option) => {
        if (!option.componentInstance.getText().toLowerCase().includes(value)) {
          option.componentInstance.hide();
        } else {
          option.componentInstance.show();
        }
      });
    },
    handleInvalid(value) {
      this.invalid = value;
    },
    switchSelectedContent(content) {
      this.$refs.option.childNodes.forEach((child) => this.$refs.option.removeChild(child));
      content.forEach((child) => this.$refs.option.appendChild(child));
    },
  },
  mounted() {
    this.tippyInstance = tippy(this.$refs.button, {
      content: this.$refs.dropdown,
      interactive: true,
      offset: [0, 0],
      onHide: () => { this.arrowUp = false; },
      onShow: () => { this.arrowUp = true; },
      placement: 'bottom-start',
      trigger: 'click',
    });

    let isInitialOptionSelected = false;
    this.$slots.default.forEach(({ componentInstance }, index) => {
      componentInstance.$on('click-option', this.handleSelectOption);
      const { getContent, getValue, isDefault } = componentInstance;
      const isFirst = index === 0 && !this.placeholder;
      const value = getValue();
      if (!isInitialOptionSelected && (isDefault() || this.value === value || isFirst)) {
        this.handleSelectOption({ content: getContent(), value });
        isInitialOptionSelected = true;
      }
    });

    this.$parent.$on('invalid', this.handleInvalid);
  },
  destroyed() {
    this.tippyInstance.destroy();
  },
};
</script>

<style lang="postcss" scoped>
.button.theme-light {
  @apply flex gap-2 rounded-md w-full hover:bg-gray-50 bg-white border-gray-300;
  @apply disabled:bg-gray-100 disabled:border-gray-200 disabled:text-gray-400;
}
</style>
