<template>
  <component
    :is="link ? 'a' : 'button'"
    class="button-custom"
    :class="[
      kind,
      shape,
      size,
      { 'text-left': textLeft },
      { 'text-right': textRight },
    ]"
    :disabled="disabled"
    :type="type"
    @click="handleClick"
  >
    <span v-if="kind === 'loading'" class="icon-container">
      <LoadingAnimation />
    </span>
    <slot name="default"></slot>
  </component>
</template>

<script>
import LoadingAnimation from './LoadingAnimation.vue';

export default {
  name: 'Button',
  props: {
    disabled: Boolean,
    kind: {
      type: String,
      default: 'default',
    },
    shape: {
      type: String,
      default: 'rectangle',
    },
    size: {
      type: String,
      default: 'normal',
    },
    type: {
      type: String,
      default: 'button',
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingAnimation,
  },
  data: () => ({
    textLeft: false,
    textRight: false,
  }),
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
  mounted() {
    const lastIndex = this.$slots.default.length - 1;
    this.$slots.default.forEach((node, index) => {
      if (node.elm.nodeName !== '#text' && !node.elm.innerText) return;
      if (node.elm.innerText && node.elm.classList.contains('sr-only')) return;
      if (index === 0) this.textLeft = true;
      if (index === lastIndex) this.textRight = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.button-custom {
  @apply cursor-pointer flex justify-center items-center gap-1.5 border whitespace-nowrap;

  &:disabled, &.loading {
    @apply bg-gray-100 border-gray-100 text-gray-400 cursor-default;

    & .icon-container > * {
      @apply max-h-5;
    }
  }

  &.default {
    @apply bg-white border border-gray-300 text-gray-900;

    &:hover {
      @apply bg-gray-50;
    }

    &:active {
      @apply bg-gray-100;
    }
  }

  &.primary {
    @apply bg-primary-700 border-primary-700 text-white font-semibold;

    &:hover {
      @apply bg-primary-800 border-primary-800;
    }

    &:active {
      @apply bg-primary-900 border-primary-900;
    }
  }

  &.primary-subtle {
    @apply bg-primary-100 border-primary-100 text-primary-700 font-semibold;

    &:hover {
      @apply bg-primary-200 border-primary-200;
    }

    &:active {
      @apply bg-primary-200 border-primary-300;
    }
  }

  &.danger-subtle {
    @apply bg-red-100 border-red-100 text-red-700 font-semibold;

    &:hover {
      @apply bg-red-200 border-red-200;
    }

    &:active {
      @apply bg-red-200 border-red-300;
    }
  }

  &.plain {
    @apply border-transparent;

    &:hover {
      @apply bg-gray-50 border-gray-300;
    }

    &:disabled {
      @apply bg-transparent border-transparent;
    }
  }

  &.normal {
    @apply h-12 lg:h-btn;
  }

  &.semi-small {
    @apply h-9 leading-5;
  }

  &.small {
    @apply h-8 leading-5;
  }

  &.rectangle {
    @apply px-2 py-2.5 rounded-md;
  }

  &.pill {
    @apply px-3 py-1 rounded-full leading-5;
  }

  &.text-left {
    @apply pl-4;
  }

  &.text-right {
    @apply pr-4;
  }
}
</style>
