<template>
  <form @submit.prevent="handleSubmit">
    <slot name="default"></slot>
  </form>
</template>

<script>
export default {
  name: 'Form',
  data: () => ({
    requiredFields: [],
  }),
  methods: {
    handleSubmit() {
      if (this.requiredFields.reduce((result, field) => field.isValid() && result, true)) {
        this.$emit('submit');
      }
    },
    reset() {
      this.requiredFields.forEach((field) => field.reset());
    },
  },
  mounted() {
    this.requiredFields = this.$children
      .filter((child) => child.isRequired && child.isRequired());
  },
};
</script>
