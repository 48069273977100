<template>
  <table class="table-custom">
    <thead>
      <tr>
        <th
          v-for="{ details, sortable, srOnly, text, showTooltip, tooltipText }, index in headers"
          @click="handleSort(sortable, index)"
          :class="{'text-right': text === 'Valor', 'cursor-pointer': sortable}"
          :key="index"
          scope="col"
        >
          <span class="inline-block">
            <span v-if="sortable" class="-ml-5">
              <AssetV3 v-if="sort.headerIndex !== index" dir="icons" name="sort.svg" />
              <AssetV3 v-else-if="sort.direction === 'ascending'" dir="icons" name="sort-asc.svg" />
              <AssetV3 v-else dir="icons" name="sort-desc.svg" />
            </span>
            <span
              :class="{ 'sr-only': srOnly }"
              class="align-middle"
            >
              {{ text }}
            </span>
            <span v-if="details" ref="details">
              <QuestionMarkCircleIcon size="20"/>
              <div ref="popover" class="popover">{{ details }}</div>
            </span>
          </span>
          <v-popover
            v-if="showTooltip"
            placement='top'
            :offset="8"
            trigger="hover"
            :autoHide="true"
            class="inline"
          >
            <p slot="popover" class="w-60">{{ tooltipText }}</p>
            <span class="px-1">
              <QuestionMarkCircleIcon size="20" class="inline text-gray-400" />
            </span>
          </v-popover>
        </th>
      </tr>
    </thead>
    <tbody>
      <slot name="default"></slot>
    </tbody>
  </table>
</template>

<script>
import tippy from 'tippy.js';
import { QuestionMarkCircleIcon } from '@vue-hero-icons/solid';
import AssetV3 from './common/AssetV3.vue';

export default {
  name: 'Table',
  model: {
    prop: 'sort',
    event: 'sort',
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AssetV3,
    QuestionMarkCircleIcon,
  },
  data: () => ({
    tippyInstances: [],
  }),
  methods: {
    handleSort(sortable, index) {
      if (!sortable) return;
      this.$emit('sort', {
        headerIndex: index,
        direction: !this.sort.direction || this.sort.direction === 'ascending'
          ? 'descending'
          : 'ascending',
      });
    },
  },
  mounted() {
    this.headers
      .filter((header) => 'details' in header)
      .forEach((_, index) => {
        this.tippyInstances.push(tippy(this.$refs.details[index], {
          arrow: true,
          content: this.$refs.popover[index],
          interactive: false,
          offset: [0, 0],
          placement: 'top-start',
          trigger: 'mouseenter focus',
        }));
      });
  },
  destroyed() {
    this.tippyInstances.forEach((instance) => instance.destroy());
  },
};
</script>

<style lang="scss" scoped>
  .table-custom {
    @apply w-full min-w-max;

    & td, & th {
      @apply px-3 py-3 lg:py-2 first:pl-4 last:pr-4 lg:first:pl-3 lg:last:pr-3;
    }

    & thead {
      @apply text-left;
    }

    & th {
      @apply font-semibold py-3 text-gray-500;
    }

    & tbody {
      @apply bg-white divide-y divide-gray-200;
    }
  }

  .table-compact {
    & td {
      @apply py-2 lg:py-1;
    }
  }
</style>
