<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-col min-h-full">
    <div class="flex items-center justify-between gap-4 bg-white border-b py-1 lg:py-2 px-6">
      <h3 class="text-gray-500 text-lg">Novo Link de pagamento</h3>
      <Button kind="plain" aria-label="Close" class="-mr-3" @click="closeModal">
        <XIcon size="24" />
        <span class="sr-only"> Fechar </span>
      </Button>
    </div>
    <div class="flex-grow bg-white lg:bg-gray-100 w-full">
      <div class="max-w-lg mx-auto bg-white p-6 pb-9 lg:border lg:rounded-xl lg:my-6">
        <header class="mb-6">
          <h1 class="flex-grow font-bold text-xl leading-9">Verificar crédito</h1>
          <p class="sub-message text-gray-500 mt-3">
            Verifique se o cliente já possui crédito pré-aprovado na Parcelex.
          </p>
        </header>
        <form class="space-y-5" @submit.prevent="applyPreApprovedCreditForPaymentLink">
          <div>
            <label class="form-label">CPF</label>
            <Input
              type="text"
              v-model="paymentLinkUser.cpf"
              @input="handleFormInput('cpf', ...arguments)"
              v-mask="'###.###.###-##'"
              required
              :errorMessage="getMessage('cpf')"
            />
          </div>
          <div class="flex flex-row gap-3">
            <Button type="submit" kind="primary"> Buscar CPF </Button>
            <Button v-if="canShowCPFFowardButton" @click="jumpStep"> Não tenho o CPF </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import { XIcon } from '@vue-hero-icons/outline';
import stringMixin from '../../../mixins/string';
import utilMixin from '../../../mixins/util';
import { Button, Input } from '../../../components';

export default {
  name: 'credit-verify',
  metaInfo: {
    title: 'Novo link de pagamentos',
  },
  components: {
    XIcon,
    Button,
    Input,
  },
  directives: {
    mask,
  },
  mixins: [stringMixin, utilMixin],
  data: () => ({
    openDropdown: false,
    formInputs: {
      cpf: {
        isValid: false,
        validateFunction: 'isValidCpf',
        errorMessage: 'CPF inválido',
        currentMessage: '',
      },
    },
  }),
  computed: {
    ...mapState('retailer', ['currentRetailer']),
    ...mapState('paymentLink', ['paymentLinkUser', 'verifyPreApprovedCreditResult']),
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    canShowCPFFowardButton() {
      return this.currentRetailer.type !== 'imobiliaria';
    },
  },
  methods: {
    ...mapActions('paymentLink', ['getPaymentUser', 'setPaymentLinkUserData']),
    ...mapActions('toast', ['showToast']),
    ...mapActions('loading', ['setIsLoading']),
    async closeModal() {
      await this.setPaymentLinkUserData({ field: 'cpf', value: '' });
      await this.$router.replace({ name: 'payment-link-list' });
    },
    async applyPreApprovedCreditForPaymentLink() {
      this.handleFormInput('cpf', this.paymentLinkUser.cpf);
      if (!this.isValidForm) {
        this.handleErrorMessages();
        return;
      }

      this.setIsLoading({ isLoading: true, loadingMessage: 'Verificando crédito' });

      try {
        const { preApprovedCredit, isExpired } = await this.getPaymentUser(
          this.paymentLinkUser.cpf,
        );

        if (preApprovedCredit.checkoutData?.result === 'PREAPPROVED' || preApprovedCredit.checkoutData?.result === 'APPROVED' || isExpired) {
          this.setPaymentLinkUserData({ field: 'cpf', value: this.paymentLinkUser.cpf });
          await this.$router.replace({ name: 'credit-verified', params: { isExpired } });
        } else if (preApprovedCredit.checkoutData?.result === 'DENIED') {
          await this.$router.replace({ name: 'denied-payment-link' });
        } else {
          await this.$router.replace({ name: 'credit-check' });
        }
      } catch (err) {
        const { status, data } = err.response;
        if (status === 404 && data === 'Pre approved credit not found') {
          await this.$router.replace({ name: 'credit-check' });
        } else {
          this.showToast({
            toastMessage: `Erro inesperado: ${data}. Contate o suporte com a mensagem`,
            toastType: 'danger',
            hideToastDelay: 5000,
          });
        }
      } finally {
        this.setIsLoading({ isLoading: false });
      }
    },
    handleFormInput(field, value) {
      if (!value) return;
      this.setPaymentLinkUserData({ field, value });
      const { validateFunction } = this.formInputs[field];
      this.formInputs[field].isValid = this[validateFunction](value);
      if (this.formInputs[field].currentMessage !== '') {
        this.formInputs[field].currentMessage = '';
      }
    },
    handleErrorMessages() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(field) {
      return this.formInputs[field].currentMessage;
    },
    async jumpStep() {
      await this.$router.replace({ name: 'create-payment-link' });
    },
    async dontHasCreditStep() {
      const field = 'cpf';
      const { cpf } = this.paymentLinkUser;
      this.setPaymentLinkUserData({ field, cpf });
      await this.$router.replace({
        name: 'credit-check',
      });
    },
  },
};
</script>

<style scoped>
.sub-message {
  font-size: 14px;
  line-height: 20px;
}
</style>
