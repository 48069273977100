var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.link ? 'a' : 'button',{tag:"component",staticClass:"button-custom",class:[
    _vm.kind,
    _vm.shape,
    _vm.size,
    { 'text-left': _vm.textLeft },
    { 'text-right': _vm.textRight },
  ],attrs:{"disabled":_vm.disabled,"type":_vm.type},on:{"click":_vm.handleClick}},[(_vm.kind === 'loading')?_c('span',{staticClass:"icon-container"},[_c('LoadingAnimation')],1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }