<template>
  <div
    ref="option"
    class="option"
    :class="{ disabled, selected, isHidden }"
    @click="handleClick"
    @select="handleSelect"
  >
    <slot name="default"></slot>
  </div>
</template>

<script>
export default {
  name: 'Option',
  props: {
    default: Boolean,
    disabled: Boolean,
    value: [String, Object, Number],
  },
  data: () => ({
    selected: false,
    isToHide: false,
  }),
  computed: {
    isHidden() {
      return this.isToHide;
    },
  },
  methods: {
    getContent() {
      return this.$slots.default.map((node) => node.elm.cloneNode(true));
    },
    getText() {
      return this.$refs.option ? this.$refs.option.innerText : '';
    },
    getValue() {
      return this.value;
    },
    isDefault() {
      return this.default;
    },
    handleClick() {
      if (this.disabled) return;
      this.selected = true;
      this.$emit('click-option', {
        content: this.getContent(),
        value: this.value,
      });
    },
    handleSelect(value) {
      if (this.disabled) return;
      this.selected = value === this.value;
    },
    hide() {
      this.isToHide = true;
    },
    show() {
      this.isToHide = false;
    },
  },
  mounted() {
    this.$parent.$on('select', this.handleSelect);
  },
};
</script>

<style lang="scss" scoped>
.option {
  @apply relative flex items-center w-full px-10 py-1.5 text-gray-900 cursor-pointer;

  &:hover {
    @apply bg-gray-100;
  }

  &.disabled {
    @apply cursor-default;
  }

  &.selected::before {
    content: url(../../public/icon-check.svg);
    @apply absolute left-3 top-2 w-5 h-5;
  }

  &.isHidden {
    @apply hidden;
  }
}
</style>
