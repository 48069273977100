<!-- eslint-disable max-len -->
<template>
  <img class="asset mirrored inline-block align-middle w-5 h-5" :loading="lazy" :src="src" :style="cssVars">
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    dir: {
      type: String,
      requided: true,
    },
    // color: {
    //   type: [String, undefined],
    //   default: undefined,
    // },
    lazy: {
      type: Boolean,
      default: true,
    },
    absolutPath: {
      type: Boolean,
      default: false,
    },
    rotation: {
      type: Number,
      default: 0,
    },
    mirrored: {
      type: Boolean,
      default: false,
    },
    convertToSVG: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseUrl() {
      return 'https://cdn.parcelex.com.br/assets/v3';
    },
    src() {
      if (!this.absolutPath) return `${this.baseUrl}/${this.dir}/${this.name}`;

      return this.convertToSVG
        ? this.name.replace('.png', '.svg').replace('.jpeg', '.svg')
        : this.name;
    },
    cssVars() {
      return {
        '--rotation': `rotate(${this.rotation}deg)`,
        // '--color': `${this.color}`
        '--mirrored': `${this.mirrored ? 'scale(-1,1)' : 'scale(1,1)'}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.asset {
  transform: var(--rotation) var(--mirrored);
}
/* .mirrored {
} */
</style>
