<template>
  <div class="flex flex-col gap-1">
  <div
    class="input-container"
    :class="[
      { disabled },
      { invalid },
      { 'textarea': type === 'textarea' }
    ]"
  >
    <div v-if="$slots.default" class="icon-container">
      <slot name="default"></slot>
    </div>

    <div class="input-text-group">
      <div v-if="addonLeft">
        {{ addonLeft }}
      </div>

      <span v-if="type === 'cash'">
        R$
      </span>
      <money
        v-if="type === 'cash'"
        id="money-input"
        class="input"
        :value="maskedValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput"
        v-bind="money"
      />

      <input
        v-if="type === 'text'"
        class="input"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="inputType"
        :value="maskedValue"
        @input="handleInput"
      />

      <textarea
        v-if="type === 'textarea'"
        class="input"
        :class="{
          'textarea': type === 'textarea'
        }"
        :placeholder="placeholder"
        :maxLength="maxLength"
        :disabled="disabled"
        :type="inputType"
        :value="maskedValue"
        @input="handleInput"
      ></textarea>

      <div v-if="addonRight">
        {{ addonRight }}
      </div>
    </div>

    <div v-if="loading" class="icon-container">
      <LoadingAnimation />
    </div>

    <button
      v-if="type === 'password-toggle'"
      type="button"
      class="icon-container"
      @click="togglePassword"
    >
      <EyeIcon v-show="!showPassword" />
      <EyeOffIcon v-show="showPassword" />
    </button>

    <button
      v-if="clipboard"
      type="button"
      class="icon-container"
      @click="copyToClipboard"
    >
      <ClipboardCopyIcon />
    </button>
  </div>
  <p
    v-if="invalid"
    class="text-sm text-red-700"
  >
    {{ required ? !hasValue ? 'Campo obrigatório' : errorMessage : errorMessage }}
  </p>
</div>
</template>

<script>
import {
  ClipboardCopyIcon,
  EyeIcon,
  EyeOffIcon,
} from '@vue-hero-icons/outline';
import { Money } from 'v-money';
import LoadingAnimation from './LoadingAnimation.vue';

export default {
  name: 'Input',
  props: {
    addonLeft: String,
    addonRight: String,
    clipboard: Boolean,
    disabled: Boolean,
    required: Boolean,
    errorMessage: String,
    loading: Boolean,
    mask: {
      type: Function,
      default: (value) => value,
    },
    unmask: {
      type: Function,
      default: (value) => value,
    },
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    maxLength: {
      type: Number,
    },
  },
  components: {
    ClipboardCopyIcon,
    EyeIcon,
    EyeOffIcon,
    LoadingAnimation,
    Money,
  },
  data: () => ({
    maskInstance: {},
    showPassword: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
    },
  }),
  computed: {
    inputType() {
      if (this.type !== 'password-toggle') return this.type;
      return this.showPassword ? 'text' : 'password';
    },
    maskedValue() {
      return this.mask(this.value);
    },
    hasValue() {
      return this.$props.value && this.$props.value.length > 0;
    },
    invalid() {
      return this.errorMessage && this.errorMessage.length > 0;
    },
  },
  methods: {
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.maskInstance.unmaskedValue);
    },
    handleInput(event) {
      const value = this.type === 'cash' ? event : event.target.value;
      this.$emit('input', this.unmask(value));
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  @apply w-full h-btn flex gap-2 px-3 items-center overflow-hidden;
  @apply border rounded-md bg-gray-200 border-gray-200 text-gray-500;

  &:hover {
    @apply border-gray-300;
  }

  &:focus-within {
    @apply bg-gray-100 border-gray-300;
  }

  &.invalid {
    @apply bg-red-50 border-red-500;
  }

  &.textarea {
    @apply h-24 min-h-full items-start resize-none;
  }

  &.disabled {
    @apply bg-gray-100 border-gray-100 text-gray-400;
  }
}

.input-text-group {
  @apply w-full h-full flex gap-2 items-baseline;
}

.input {
  @apply w-full h-full px-0 bg-transparent border-0 ring-0
  placeholder-gray-500 text-gray-900 outline-none;

  &:disabled {
    @apply bg-gray-100 border-gray-100 text-gray-400;
  }

  &.textarea {
  @apply h-24 min-h-full resize-none;
}

}
.icon-container > * {
  @apply w-6 h-6;
}
</style>
