<template>
  <div class="field">
    <slot name="default"></slot>
    <div v-if="invalid">
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import inputMasksMixin from '@/mixins/inputMasks';
import stringMixin from '@/mixins/string';

export default {
  name: 'Field',
  props: {
    errorMessage: String,
    required: [Boolean, RegExp, Function],
  },
  mixins: [
    inputMasksMixin,
    stringMixin,
  ],
  data: () => ({
    invalid: false,
    value: undefined,
  }),
  methods: {
    defaultValidateFunction() {
      return this.value && /.+/i.test(this.value);
    },
    handleEvent(value) {
      this.value = value;
      this.$emit('invalid', false);
    },
    isRequired() {
      return !!this.required;
    },
    isValid() {
      if (!this.required) return true;
      let isValid = true;
      if (typeof this.required === 'object') isValid = this.required.test(this.value);
      else if (typeof this.required === 'function') isValid = this.required(this.value);
      else isValid = this.defaultValidateFunction();
      this.$emit('invalid', !isValid);
      return isValid;
    },
    reset() {
      this.value = undefined;
    },
  },
  mounted() {
    this.$slots.default.forEach((child) => {
      child.componentInstance.$on('input', this.handleEvent);
      child.componentInstance.$on('select', this.handleEvent);
    });
  },
};
</script>

<style lang="scss" scoped>
.field {
  @apply flex-row;
}

.error-message {
  @apply text-red-700 text-sm mt-1.5;
}
</style>
