<!-- eslint-disable max-len -->
<template>
  <div :class="{ 'full-width': fullWidth, dropdown, invalid }" class="datepicker relative">
    <span v-if="icon" class="absolute left-3 top-3 lg:top-2 pointer-events-none">
      <CalendarIcon size="24" />
    </span>
    <input :placeholder="placeholder" class="datepicker-input pl-11 placeholder-gray-900" ref="datePicker" :class="{'theme-light': white }" />
    <span class="absolute top-3.5 lg:top-2.5 right-4">
      <ChevronDownIcon class="transform transition-transform duration-300" size="20" :class="arrowUp? 'rotate-180' : ''" />
    </span>
  </div>
</template>

<script>
import flatpickr from 'flatpickr';
// eslint-disable-next-line
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import {
  CalendarIcon,
} from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';

export default {
  name: 'DatePicker',
  props: {
    defaultDate: [Date, Array],
    dropdown: Boolean,
    fullWidth: Boolean,
    icon: Boolean,
    maxDate: [String, Date],
    minDate: [String, Date],
    mode: {
      type: String,
      default: 'single',
    },
    placeholder: String,
    value: [Date, Array],
    white: Boolean,
  },
  components: {
    CalendarIcon,
    ChevronDownIcon,
  },
  data: () => ({
    arrowUp: false,
    flatpickrInstance: null,
    invalid: false,
  }),
  methods: {
    handleInput(selectedDates) {
      this.$emit('input', selectedDates);
    },
    handleInvalid(value) {
      this.invalid = value;
    },
  },
  mounted() {
    this.$parent.$on('invalid', this.handleInvalid);

    const { handleInput } = this;

    const initialDate = this.defaultDate || this.value;

    const flatpickrConfig = {
      dateFormat: 'd/m/Y',
      defaultDate: initialDate,
      locale: Portuguese,
      maxDate: this.maxDate,
      minDate: this.minDate,
      mode: this.mode,
      onOpen: () => { this.arrowUp = true; },
      onClose: () => { this.arrowUp = false; },
      onChange(selectedDates) {
        handleInput(selectedDates);
      },
    };

    this.flatpickrInstance = flatpickr(this.$refs.datePicker, flatpickrConfig);
    flatpickr('#datePickerToggle', flatpickrConfig);

    if (initialDate) {
      handleInput(initialDate);
    }

    const style = document.createElement('style');

    style.appendChild(document.createTextNode(`
      .flatpickr-calendar {
        overflow: hidden;
        border-radius: 12px;
      }

      .flatpickr-calendar,
      .flatpickr-innerContainer {
        border: none;
      }

      .flatpickr-days {
        border: 1px solid #CBD5E1 !important;
        border-top: none !important;
        border-radius: 0 0 12px 12px
      }

      .flatpickr-month,
      .flatpickr-monthDropdown-months,
      .flatpickr-monthDropdown-month,
      .flatpickr-weekdays,
      .flatpickr-weekday {
        background: #20627A !important;
      }

      .flatpickr-monthDropdown-months,
      .numInput.cur-year {
        font-weight: 600 !important;
      }

      .flatpickr-current-month {
        padding: 4px 34px 0 34px;
        width: 100%;
        left: initial;
        position: relative;
        font-size: inherit;
        font-weight: inherit;
        height: auto;
        display: flex;
        text-align: left;
        font-size: 14px !important;
      }

      .flatpickr-current-month .flatpickr-monthDropdown-months {
        text-transform: uppercase;
        flex-grow: 1;
        letter-spacing: 1px;
        background: url('https://s3.amazonaws.com/cdn.parcelex.com.br/assets/v3/icons/datepicker-chevron-down.svg') no-repeat  right 12px center !important;
        margin: 0;
        padding: 0 12px;
        height: 28px;
        border-radius: 14px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
        background-color: rgba(0,0,0,0.2) !important;
      }

      .flatpickr-current-month .flatpickr-monthDropdown-months:focus {
        box-shadow:inset 0 0 0 1px rgba(255,255,255,0.5);
      }

      .flatpickr-current-month .numInputWrapper {
        width: 80px;
      }

      .flatpickr-current-month input.cur-year {
        height: 28px;
        padding: 0;
        padding-left: 12px;
        font-size: 16px !important;
      }

      .flatpickr-weekday {
        color: #FFFFFF !important;
        font-weight: 600 !important;
        text-transform: uppercase;
      }

      .flatpickr-day.startRange {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background: #20627A;
        border-color: #20627A;
      }

      .flatpickr-day.endRange {
        position: relative;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        background: #20627A;
        border-color: #20627A;
      }

      .flatpickr-day.startRange + .flatpickr-day.endRange::before {
        content: '';
        position: absolute;
        background: #20627A;
        width: 10px;
        height: 39px;
        left: -11px;
        top: -1px;
      }

      .flatpickr-day {
        color: #0F172A;
      }

      .flatpickr-day:hover {
        background: #E2E8F0;
        border-color: #E2E8F0;
      }

      .flatpickr-day.today {
        border-color: #20627A;
      }

      .flatpickr-day.today:hover, .flatpickr-day.today:focus {
        background: #E2E8F0;
        border-color: #E2E8F0;
        color: #0F172A;
      }

      .flatpickr-day.prevMonthDay:not(.flatpickr-disabled),
      .flatpickr-day.nextMonthDay:not(.flatpickr-disabled) {
        color: #94A3B8;
      }

      .flatpickr-prev-month svg,
      .flatpickr-next-month svg {
        display: none;
      }

      .flatpickr-prev-month {
        background: url('https://s3.amazonaws.com/cdn.parcelex.com.br/assets/v3/icons/datepicker-chevron-left.svg') no-repeat center !important;
        width: 34px;
      }

      .flatpickr-next-month {
        background: url('https://s3.amazonaws.com/cdn.parcelex.com.br/assets/v3/icons/datepicker-chevron-right.svg') no-repeat center !important;
        width: 34px;
      }

      .flatpickr-day.inRange {
        position: relative;
        background: #E2E8F0;
        border-color: #E2E8F0;
      }

      .flatpickr-day.inRange::before {
        content: '';
        position: absolute;
        background: #E2E8F0;
        width: 6px;
        height: 39px;
        left: -7px;
        top: -1px;
      }

      .flatpickr-day.inRange::after {
        content: '';
        position: absolute;
        background: #E2E8F0;
        width: 6px;
        height: 39px;
        right: -7px;
        top: -1px;
      }

      .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
        background: #E2E8F0;
        border-color: #E2E8F0;
      }

      .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
        background: #20627A;
        border-color: #20627A;
        color: #fff;
      }
    `));

    document.head.appendChild(style);
  },
};
</script>

<style src="flatpickr/dist/themes/material_blue.css"></style>

<!-- eslint-disable max-len -->
<style lang="postcss" scoped>
.datepicker-input {
  @apply flex rounded-md w-full bg-gray-200 border-transparent cursor-pointer disabled:bg-gray-100 disabled:border-gray-200 disabled:text-gray-400 h-12 lg:h-btn;
}

.datepicker-input.theme-light {
  @apply bg-white border-gray-300 hover:bg-gray-50;
}

.datepicker:not(.invalid) .datepicker-input {
  @apply hover:border-gray-300 focus:bg-gray-100 focus:border-gray-300 focus:ring-0;
}

.datepicker.invalid .datepicker-input {
  @apply border-red-600 bg-red-50 placeholder-red-600;
}

.datepicker.invalid {
  @apply text-red-600;
}
</style>
