import moment from '../lib/moment-ptbr';

function moneyFilter(raw) {
  if (typeof raw !== 'number') return raw;
  return raw.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

function interestRateFilter(raw) {
  if (typeof raw !== 'number') return raw;
  return `${(raw * 100).toFixed(2).replace('.', ',')}%`;
}

function floatFilter(raw) {
  if (typeof raw !== 'number') return raw;
  return raw.toFixed(2).replace('.', ',').replace(' ', '');
}

function phoneFilter(raw) {
  if (typeof raw !== 'string') return raw;
  const sanitizedPhone = raw.replace(/[.-]/g, '');
  return `(${sanitizedPhone.substring(0, 2)}) ${sanitizedPhone.substring(2, 7)}-${sanitizedPhone.substring(7)}`;
}

function phoneMask(phone) {
  if (!phone) return phone;
  return `(${phone.substring(0, 2)}) ${phone.substring(2, 5)}**-**${phone.substring(9, 11)}`;
}

function cpfFilter(raw) {
  return `${raw.substring(0, 3)}.${raw.substring(3, 6)}.${raw.substring(6, 9)}-${raw.substring(9)}`;
}

function capitalize(s, opt = {}) {
  if (typeof s !== 'string') return '';
  const res = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  if (!opt.first) return res;
  return res.split(' ')[0];
}

function capitalizeInSpaces(raw) {
  if (typeof raw !== 'string') return raw;
  return raw
    .split(' ')
    .map((el) => capitalize(el))
    .join(' ');
}

function maxNameFilter(raw) {
  if (typeof raw !== 'string' || raw.length <= 40) return raw;
  return `${raw.substring(0, 41)}...`;
}

function dateFilter(raw) {
  if (!raw || typeof raw !== 'string') return raw;
  return moment(raw).format('DD/MM/YYYY');
}

function hourFilter(raw) {
  if (!raw || typeof raw !== 'string') return raw;
  return moment(raw).format('HH:mm');
}

function completeDateFilterText(raw) {
  if (!raw || typeof raw !== 'string') return raw;
  const dateInMoment = moment(raw);
  return `${dateInMoment.format('DD/MM/YYYY')} às ${dateInMoment.format('HH:mm')}`;
}

function censorCpfFilter(raw) {
  if (!raw || typeof raw !== 'string') return raw;
  return `•••.${raw.substring(3, 6)}.${raw.substring(6, 9)}-••`;
}

export default {
  methods: {
    moneyFilter,
    floatFilter,
    interestRateFilter,
    capitalize,
    capitalizeInSpaces,
    phoneFilter,
    phoneMask,
    cpfFilter,
    maxNameFilter,
    dateFilter,
    hourFilter,
    completeDateFilterText,
    censorCpfFilter,
  },
  filters: {
    moneyFilter,
    floatFilter,
    interestRateFilter,
    capitalize,
    capitalizeInSpaces,
    phoneFilter,
    phoneMask,
    cpfFilter,
    maxNameFilter,
    dateFilter,
    hourFilter,
    completeDateFilterText,
    censorCpfFilter,
  },
};
