<template>
  <div class="table-pagination">
    <div class="table-pagination-info">
      Exibindo {{ firstItemNumber }}-{{ lastItemNumber }} de {{ numberOfItems }} resultados
    </div>
    <div class="table-pagination-control">
      <Button
        :disabled="currentPage <= 0"
        kind="plain"
        size="semi-small"
        class="w-9"
        @click="goToPreviousPage"
      >
        <ChevronLeftIcon size="20"/>
      </Button>
      <Button
        v-if="numberOfPages > maxNumberOfButtons && currentPage >= 2"
        kind="plain"
        size="semi-small"
        class="w-9"
        @click="goToSpecificPage(0)"
      >
        1
      </Button>
      <div
        v-if="numberOfPages > maxNumberOfButtons && currentPage >= 2"
        class="table-pagination-control-icon-container"
      >
        <span>...</span>
      </div>
      <Button
        v-for="pageNumber in numberOfButtons"
        :key="pageNumber"
        :kind="currentPage === pageNumber ? 'primary-subtle' : 'plain'"
        size="semi-small"
        class="w-9"
        @click="goToSpecificPage(pageNumber)"
      >
        {{ pageNumber + 1 }}
      </Button>
      <div
        v-if="numberOfPages > maxNumberOfButtons && Math.abs(currentPage - numberOfPages) > 2"
        class="table-pagination-control-icon-container"
      >
        <span>...</span>
      </div>
      <Button
        v-if="numberOfPages > maxNumberOfButtons && Math.abs(currentPage - numberOfPages) > 2"
        kind="plain"
        size="semi-small"
        class="w-9"
        @click="goToSpecificPage(numberOfPages - 1)"
      >
        {{ numberOfPages }}
      </Button>
      <Button
        :disabled="currentPage >= numberOfPages - 1"
        kind="plain"
        size="semi-small"
        class="w-9"
        @click="goToNextPage"
      >
        <ChevronRightIcon size="20"/>
      </Button>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/outline';
// eslint-disable-next-line import/no-cycle
import { Button } from '@/components';

export default {
  name: 'Pagination',
  model: {
    prop: 'currentPage',
    event: 'pagination',
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    numberOfItems: {
      type: Number,
      required: true,
    },
  },
  components: {
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data: () => ({
    maxNumberOfButtons: 3,
    minNumberOfButtons: 1,
  }),
  computed: {
    firstItemNumber() {
      return (this.itemsPerPage * this.currentPage) + 1;
    },
    lastItemNumber() {
      const nextPageNumber = this.currentPage + 1;
      const lastItemNumber = this.itemsPerPage * nextPageNumber;
      return Math.min(lastItemNumber, this.numberOfItems);
    },
    numberOfButtons() {
      const numberOfButtons = Math.max(
        Math.min(this.numberOfPages, this.maxNumberOfButtons),
        this.minNumberOfButtons,
      );
      const numberOfButtonsOnEachSide = Math.floor((numberOfButtons - 1) / 2);
      let firstButtonNumber = Math.max(this.currentPage - numberOfButtonsOnEachSide, 0);
      const lastButtonNumber = firstButtonNumber + numberOfButtons - 1;
      if (lastButtonNumber > this.numberOfPages - 1) {
        firstButtonNumber -= lastButtonNumber - (this.numberOfPages - 1);
      }
      const buttons = [];
      for (let i = 0; i < numberOfButtons; i += 1) {
        buttons.push(firstButtonNumber + i);
      }
      return buttons;
    },
    numberOfPages() {
      return Math.max(
        Math.ceil(this.numberOfItems / this.itemsPerPage),
        this.minNumberOfButtons,
      );
    },
  },
  methods: {
    goToNextPage() {
      this.$emit('pagination', this.currentPage + 1);
    },
    goToPreviousPage() {
      this.$emit('pagination', this.currentPage - 1);
    },
    goToSpecificPage(pageNumber) {
      this.$emit('pagination', pageNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-pagination {
  @apply flex items-center justify-center lg:justify-between py-5 lg:py-2 px-3 border-t text-sm;
}

.table-pagination-info {
  @apply hidden lg:block text-sm text-gray-500;
}

.table-pagination-control {
  @apply flex gap-1;
}

.table-pagination-control-icon-container {
  @apply w-9 h-9 p-1.5 flex justify-center items-end;
}
</style>
